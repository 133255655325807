define("discourse/plugins/hide-add-remove-participant-btn/discourse/initializers/init-remove-add-remove-participant-btn", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'init-remove-add-remove-participant-btn',
    initialize(container) {
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange((url, title) => {
          const newPrivateMessageButtons = document.querySelectorAll('.add-remove-participant-btn');
          newPrivateMessageButtons.forEach(btn => {
            btn.remove();
          });
        });
      });
    }
  };
});